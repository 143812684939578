












































































































import { Component, Vue, Prop } from "vue-property-decorator";
import echarts from "echarts";
import {
  GetOverview,
  GetRenwuZhuangtaiLeft,
  GetRenwuZhuangtaiRight,
  GetFenpeiNum,
} from "@/request/mark";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private current: any = {};
  private zhuangtaiPie: any = {};
  private shuliangPie: any = {};
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private statusData: any = [];
  private shuliangData: any = [];
  private overviewData: any = {};
  private zhuangtaiLeftData: any = [];
  /**
   * @description 计算各机构服务量分布的背景
   */
  private calBackground(index: any, item: any, first: any) {
    const max = first.value;
    if (!this.$refs.zongliang as any) {
      return {};
    }
    // 总共40个颜色，除40取余
    const style: any = {};
    style.backgroundColor = this.colors[index % 40];
    if (!style.backgroundColor) {
      style.backgroundColor = "#000000";
    }
    const w = (item.value / max) * (this.$refs.zongliang as any).offsetWidth;
    if (w > 0 && w < 40) {
      style.width = 40 + "px";
    } else {
      if (w > 100) {
        style.width = w - 60 + "px";
      } else {
        style.width = w + "px";
      }
    }
    // style.width =
    //   (item.value / max) * (this.$refs.zongliang as any).offsetWidth +
    //   40 +
    //   "px";
    return style;
  }
  private drawStatusPie() {
    GetRenwuZhuangtaiLeft(this).then((res: any) => {
      this.zhuangtaiLeftData = res.data;
      if (res.data.length === 0) {
        this.current = {};
        this.getRenwuZhuangtaiData();
        return;
      } else {
        this.current = res.data[0];
      }
      setTimeout(() => {
        const Chart: any = echarts.init(
          this.$refs.Echarts as HTMLCanvasElement
        );

        const options = {
          color: this.colors,
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [
            {
              type: "pie",
              data: res.data,
              radius: ["50%", "70%"],
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "30",
                  fontWeight: "bold",
                },
              },
              center: ["60%", "50%"],
            },
          ],
        };
        Chart.setOption(options, true);
        // 点击事件
        Chart.off("click");
        Chart.on("click", (p: any) => {
          this.current = p;
          this.getRenwuZhuangtaiData();
        });
        this.getRenwuZhuangtaiData();
      }, 1000);
    });
  }
  private getRenwuZhuangtaiData() {
    const params: any = {
      name: this.current.name,
    };
    GetRenwuZhuangtaiRight(this, params).then((res: any) => {
      this.statusData = res.data;
    });
  }
  private drawShuliangPie() {
    GetFenpeiNum(this).then((res: any) => {
      this.shuliangData = res.data;
      this.shuliangPie = {
        color: this.colors,
        tooltip: {
          trigger: "item",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            type: "pie",
            data: this.shuliangData,
            radius: ["50%", "70%"],
            label: {
              show: false,
            },
            center: ["60%", "50%"],
          },
        ],
      };
    });
  }
  private getOverview() {
    GetOverview(this).then((res: any) => {
      this.overviewData = res;
    });
  }
  private mounted() {
    this.getOverview();
    this.drawStatusPie();
    this.drawShuliangPie();
  }
}
